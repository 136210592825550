<template>
  <aside class="fixed left-0 top-0 bottom-0 w-64 bg-primary text-white">
    <div class="h-full flex flex-col">
      <!-- Logo -->
      <div class="p-6 border-b border-white/10">
        <router-link to="/" class="flex items-center space-x-2">
          <span class="text-2xl font-bold tracking-wide text-white">INSPECTAMAN</span>
        </router-link>
      </div>

      <!-- Navigation Links -->
      <nav class="flex-1 p-4">
        <ul class="space-y-2">
          <li>
            <router-link to="/dashboard" :class="['flex items-center space-x-3 px-4 py-3 rounded-lg transition-colors duration-200', isActiveRoute('/dashboard') ? 'bg-white/20' : 'hover:bg-white/10']">
              <svg class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
              </svg>
              <span>Dashboard</span>
            </router-link>
          </li>
          <li>
            <router-link to="/inspections" :class="['flex items-center space-x-3 px-4 py-3 rounded-lg transition-colors duration-200', isActiveRoute('/inspections') ? 'bg-white/20' : 'hover:bg-white/10']">
              <svg class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
              </svg>
              <span>Inspections</span>
            </router-link>
          </li>
          <li>
            <router-link to="/reports" :class="['flex items-center space-x-3 px-4 py-3 rounded-lg transition-colors duration-200', isActiveRoute('/reports') ? 'bg-white/20' : 'hover:bg-white/10']">
              <svg class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
              </svg>
              <span>Reports</span>
            </router-link>
          </li>
          <li>
            <router-link to="/bookings" :class="['flex items-center space-x-3 px-4 py-3 rounded-lg transition-colors duration-200', isActiveRoute('/bookings') ? 'bg-white/20' : 'hover:bg-white/10']">
              <svg class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
              </svg>
              <span>Bookings</span>
            </router-link>
          </li>
          <li>
            <router-link to="/payments" :class="['flex items-center space-x-3 px-4 py-3 rounded-lg transition-colors duration-200', isActiveRoute('/payments') ? 'bg-white/20' : 'hover:bg-white/10']">
              <svg class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <span>Payments</span>
            </router-link>
          </li>
        </ul>       
      </nav>

      <!-- User Profile -->
      <div class="p-4 border-t border-white/10">
        <div class="flex items-center space-x-3 px-4 py-3">
          <div class="w-8 h-8 rounded-full bg-white/10 flex items-center justify-center">
            <svg class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
            </svg>
          </div>
          <div class="flex-1">
            <p class="text-sm font-medium">{{ userProfile?.name || 'Loading...' }}</p>
            <p class="text-xs text-white/70">{{ userRole }}</p>
          </div>
        </div>
        <router-link to="/profile" :class="['flex items-center space-x-3 px-4 py-3 mt-2 rounded-lg transition-colors duration-200', isActiveRoute('/profile') ? 'bg-white/20' : 'hover:bg-white/10']">
          <svg class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg>
          <span>Account Settings</span>
        </router-link>
      </div>
    </div>
  </aside>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useRoute } from 'vue-router'

const authStore = useAuthStore()
const route = useRoute()

const isActiveRoute = (path: string) => {
  return route.path === path
}

const userProfile = computed(() => authStore.userProfile)
const userRole = computed(() => {
  switch (authStore.userRole) {
    case 'admin':
      return 'Administrator'
    case 'inspector':
      return 'Inspector'
    default:
      return 'Client'
  }
})

const isAdminOrInspector = computed(() => {
  return ['admin', 'inspector'].includes(authStore.userRole)
})
</script>