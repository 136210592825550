<template>
  <DashboardLayout>
    <div class="max-w-4xl mx-auto">
      <div class="bg-white dark:bg-dark-surface rounded-xl shadow-lg p-6">
        <h2 class="text-2xl font-semibold mb-6">Product Configuration</h2>

        <!-- Product Form -->
        <form @submit.prevent="handleSubmit" class="space-y-6">
          <!-- Basic Info -->
          <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label class="block text-sm font-medium mb-2">Product Name</label>
              <input
                v-model="form.name"
                type="text"
                required
                class="w-full px-4 py-2 rounded-xl border focus:ring-2 focus:ring-primary"
                placeholder="Enter product name"
              />
            </div>
            <div>
              <label class="block text-sm font-medium mb-2">Base Price</label>
              <input
                v-model.number="form.base_price"
                type="number"
                required
                min="0"
                step="0.01"
                class="w-full px-4 py-2 rounded-xl border focus:ring-2 focus:ring-primary"
                placeholder="Enter base price"
              />
            </div>
          </div>

          <!-- Product Type Selection -->
          <div>
            <label class="block text-sm font-medium mb-2">Product Type</label>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div
                @click="selectProductType('inspection')"
                :class="[
                  'p-4 rounded-xl border-2 cursor-pointer transition-all duration-200',
                  form.type === 'inspection'
                    ? 'border-primary bg-primary/5'
                    : 'border-gray-200 hover:border-primary/50'
                ]"
              >
                <div class="flex items-center mb-2">
                  <div class="w-5 h-5 rounded-full border-2 flex items-center justify-center mr-3"
                       :class="form.type === 'inspection' ? 'border-primary' : 'border-gray-400'">
                    <div v-if="form.type === 'inspection'" class="w-3 h-3 rounded-full bg-primary"></div>
                  </div>
                  <h5 class="font-medium">Inspection Product</h5>
                </div>
                <p class="text-sm text-gray-600 ml-8">
                  For products that require AQL-based inspection (e.g., manufactured goods)
                </p>
              </div>

              <div
                @click="selectProductType('service')"
                :class="[
                  'p-4 rounded-xl border-2 cursor-pointer transition-all duration-200',
                  form.type === 'service'
                    ? 'border-primary bg-primary/5'
                    : 'border-gray-200 hover:border-primary/50'
                ]"
              >
                <div class="flex items-center mb-2">
                  <div class="w-5 h-5 rounded-full border-2 flex items-center justify-center mr-3"
                       :class="form.type === 'service' ? 'border-primary' : 'border-gray-400'">
                    <div v-if="form.type === 'service'" class="w-3 h-3 rounded-full bg-primary"></div>
                  </div>
                  <h5 class="font-medium">Service Product</h5>
                </div>
                <p class="text-sm text-gray-600 ml-8">
                  For services without AQL requirements (e.g., factory audit)
                </p>
              </div>
            </div>
          </div>

          <!-- Inspection Capacity (for both types) -->
          <div>
            <label class="block text-sm font-medium mb-2">Inspector Capacity (units/day)</label>
            <input
              v-model.number="form.inspector_capacity"
              type="number"
              required
              min="1"
              class="w-full px-4 py-2 rounded-xl border focus:ring-2 focus:ring-primary"
              placeholder="Enter inspector capacity"
            />
          </div>

          <!-- AQL Rules (only for inspection type) -->
          <div v-if="form.type === 'inspection'" class="space-y-4">
            <h3 class="text-lg font-medium">AQL Rules</h3>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <label class="block text-sm font-medium mb-2">Sample Size</label>
                <input
                  v-model.number="form.aql_rules.sample_size"
                  type="number"
                  required
                  min="1"
                  class="w-full px-4 py-2 rounded-xl border focus:ring-2 focus:ring-primary"
                  placeholder="Enter sample size"
                />
              </div>
              <div>
                <label class="block text-sm font-medium mb-2">Acceptance Number</label>
                <input
                  v-model.number="form.aql_rules.acceptance_number"
                  type="number"
                  required
                  min="0"
                  class="w-full px-4 py-2 rounded-xl border focus:ring-2 focus:ring-primary"
                  placeholder="Enter acceptance number"
                />
              </div>
              <div>
                <label class="block text-sm font-medium mb-2">Rejection Number</label>
                <input
                  v-model.number="form.aql_rules.rejection_number"
                  type="number"
                  required
                  min="1"
                  class="w-full px-4 py-2 rounded-xl border focus:ring-2 focus:ring-primary"
                  placeholder="Enter rejection number"
                />
              </div>
            </div>
          </div>

          <!-- Description -->
          <div>
            <label class="block text-sm font-medium mb-2">Description</label>
            <textarea
              v-model="form.description"
              rows="3"
              class="w-full px-4 py-2 rounded-xl border focus:ring-2 focus:ring-primary"
              placeholder="Enter product description"
            ></textarea>
          </div>

          <!-- Submit Button -->
          <div class="flex justify-end">
            <BaseButton
              type="submit"
              variant="primary"
              :loading="loading"
            >
              {{ editingId ? 'Update Product' : 'Create Product' }}
            </BaseButton>
          </div>
        </form>

        <!-- Product List -->
        <div class="mt-12">
          <h3 class="text-xl font-semibold mb-4">Existing Products</h3>
          <div class="space-y-4">
            <div v-for="product in products" :key="product.id" 
                 class="bg-gray-50 dark:bg-gray-800/50 rounded-xl p-4">
              <div class="flex justify-between items-start">
                <div>
                  <h4 class="font-medium">{{ product.name }}</h4>
                  <p class="text-sm text-gray-600">
                    {{ product.type === 'inspection' ? 'Inspection Product' : 'Service Product' }} - 
                    ${{ product.base_price }}
                  </p>
                  <p class="text-sm text-gray-500 mt-1">
                    Capacity: {{ product.inspector_capacity }} units/day
                  </p>
                </div>
                <div class="flex space-x-2">
                  <button
                    @click="editProduct(product)"
                    class="text-primary hover:text-primary-dark"
                  >
                    Edit
                  </button>
                  <button
                    @click="deleteProduct(product.id)"
                    class="text-red-500 hover:text-red-600"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { collection, addDoc, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore'
import { db } from '@/firebase'
import DashboardLayout from '@/components/DashboardLayout.vue'
import BaseButton from '@/components/BaseButton.vue'

interface AQLRules {
  sample_size: number
  acceptance_number: number
  rejection_number: number
}

interface Product {
  id: string
  name: string
  type: 'inspection' | 'service'
  base_price: number
  inspector_capacity: number
  description?: string
  aql_rules?: AQLRules
}

const loading = ref(false)
const products = ref<Product[]>([])
const editingId = ref<string | null>(null)

const form = ref({
  name: '',
  type: 'inspection' as 'inspection' | 'service',
  base_price: 0,
  inspector_capacity: 0,
  description: '',
  aql_rules: {
    sample_size: 0,
    acceptance_number: 0,
    rejection_number: 0
  }
})

function selectProductType(type: 'inspection' | 'service') {
  form.value.type = type
}

function resetForm() {
  form.value = {
    name: '',
    type: 'inspection',
    base_price: 0,
    inspector_capacity: 0,
    description: '',
    aql_rules: {
      sample_size: 0,
      acceptance_number: 0,
      rejection_number: 0
    }
  }
  editingId.value = null
}

async function fetchProducts() {
  try {
    const querySnapshot = await getDocs(collection(db, 'products'))
    products.value = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as Product[]
  } catch (error) {
    console.error('Error fetching products:', error)
  }
}

async function handleSubmit() {
  try {
    loading.value = true
    const productData = {
      name: form.value.name,
      type: form.value.type,
      base_price: form.value.base_price,
      inspector_capacity: form.value.inspector_capacity,
      description: form.value.description,
      ...(form.value.type === 'inspection' && {
        aql_rules: form.value.aql_rules
      })
    }

    if (editingId.value) {
      await updateDoc(doc(db, 'products', editingId.value), productData)
    } else {
      await addDoc(collection(db, 'products'), productData)
    }

    await fetchProducts()
    resetForm()
  } catch (error) {
    console.error('Error saving product:', error)
  } finally {
    loading.value = false
  }
}

function editProduct(product: Product) {
  editingId.value = product.id
  form.value = {
    name: product.name,
    type: product.type,
    base_price: product.base_price,
    inspector_capacity: product.inspector_capacity,
    description: product.description || '',
    aql_rules: product.aql_rules || {
      sample_size: 0,
      acceptance_number: 0,
      rejection_number: 0
    }
  }
}

async function deleteProduct(id: string) {
  if (!confirm('Are you sure you want to delete this product?')) return

  try {
    await deleteDoc(doc(db, 'products', id))
    await fetchProducts()
  } catch (error) {
    console.error('Error deleting product:', error)
  }
}

onMounted(fetchProducts)
</script>