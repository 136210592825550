import { collection, addDoc, query, where, getDocs, doc, updateDoc, orderBy } from 'firebase/firestore'
import { db, auth } from '../firebase'

const API_URL = import.meta.env.VITE_API_URL
const MAX_RETRIES = Number(import.meta.env.VITE_NOTIFICATION_RETRY_ATTEMPTS) || 3
const RETRY_DELAY = 1000 // 1 second delay between retries

// Interfaces for type safety
export interface Notification {
  id: string
  userId?: string
  type: string
  bookingId: string
  message: string
  read: boolean
  createdAt: Date
}

export interface BookingProduct {
  id?: string
  name: string
  base_price?: number
  inspector_capacity?: number
  description?: string
  aql_rules?: {
    sample_size: number
    acceptance_number: number
    rejection_number: number
  }
}

export interface BookingDetails {
  selectedProduct: BookingProduct;
  date: string;
  time: string;
  factoryName: string;
  factoryAddress: string;
  factoryEmail: string;
  companyName: string;
  clientEmail: string;
  inspectorEmail?: string | null;
  quantity: number;
  productName: string;
  productDescription: string;
  destinationCountry: string;
  orderNumber: string;
  notes?: string;
  useAQL?: boolean;
  totalCost?: number;
  inspectorsNeeded?: number;
  quantityMessage?: string;
  aqlInfo?: {
    sampleSize: number;
    acceptanceNumber: number;
    rejectionNumber: number;
  } | null;
  productInfo?: {
    images?: Array<{ url: string; description?: string }>;
    documents?: Array<{ url: string; name: string; description?: string }>;
  };
  inspectionDetails?: {
    type: 'AQL' | 'Custom';
    totalUnits: number;
    sampleSize?: number;
    inspectionUnits?: number;
    inspectorsNeeded: number;
    coverage: string;
    aqlLevel?: string;
    acceptanceCriteria?: {
      majorDefects: number;
      minorDefects: number;
    };
    methodology?: string;
    inspectionRate?: string;
    inspectorCapacity?: string;
    displayQuantity: string;
  };
}

// Email validation helper
function isValidEmail(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}

// Sleep helper for retry delay
const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

export async function getUserNotifications(userId: string): Promise<Notification[]> {
  try {
    const notificationsRef = collection(db, 'notifications')
    const q = query(
      notificationsRef,
      where('userId', '==', userId),
      orderBy('createdAt', 'desc')
    )
    
    const querySnapshot = await getDocs(q)
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt.toDate()
    })) as Notification[]
  } catch (error) {
    console.error('Error fetching notifications:', error)
    throw error
  }
}

export async function markNotificationAsRead(notificationId: string) {
  try {
    const notificationRef = doc(db, 'notifications', notificationId)
    await updateDoc(notificationRef, {
      read: true
    })
  } catch (error) {
    console.error('Error marking notification as read:', error)
    throw error
  }
}

async function sendEmailNotifications(bookingData: Partial<BookingDetails>, inspectorId: string | null): Promise<any> {
  // Check if email notifications are enabled
  const emailNotificationsEnabled = import.meta.env.VITE_ENABLE_EMAIL_NOTIFICATIONS === 'true'
  if (!emailNotificationsEnabled) {
    console.log('Email notifications are disabled in the current environment');
    return {
      status: 'skipped',
      message: 'Email notifications are disabled in the current environment'
    };
  }

  if (!API_URL) {
    throw new Error('API_URL is not configured. Please check your environment variables.')
  }

  // Validate and normalize client email
  const clientEmail = bookingData.clientEmail || auth.currentUser?.email
  if (!clientEmail || !isValidEmail(clientEmail)) {
    throw new Error(`Invalid client email: ${clientEmail}`)
  }

  // Validate factory email
  if (!bookingData.factoryEmail || !isValidEmail(bookingData.factoryEmail)) {
    throw new Error(`Invalid factory email: ${bookingData.factoryEmail}`)
  }

  // Helper function to validate string fields
  const validateString = (value: unknown, fieldName: string): string => {
    if (typeof value !== 'string' || !value.trim()) {
      throw new Error(`${fieldName} is required and must be a non-empty string`)
    }
    return value.trim()
  }

  let lastError: Error | null = null
  for (let attempt = 1; attempt <= MAX_RETRIES; attempt++) {
    try {
      console.log(`Attempt ${attempt} of ${MAX_RETRIES} to send email notifications`)

      // Validate required fields
      const validatedData = {
        factoryEmail: validateString(bookingData.factoryEmail, 'Factory email'),
        companyName: validateString(bookingData.companyName, 'Company name'),
        productName: validateString(bookingData.productName, 'Product name'),
        date: validateString(bookingData.date, 'Date'),
        time: validateString(bookingData.time, 'Time'),
      }

      // Get quantity from service details
      let quantity: number
      if (bookingData.useAQL && bookingData.selectedProduct?.aql_rules?.sample_size) {
        quantity = bookingData.selectedProduct.aql_rules.sample_size
      } else if (typeof bookingData.quantity === 'number' && bookingData.quantity > 0) {
        quantity = bookingData.quantity
      } else {
        throw new Error('Invalid quantity. Please complete the service details step.')
      }

      // Create booking details with validated data and map fields for backend
      const bookingDetails = {
        selectedProduct: bookingData.selectedProduct || { name: 'Unknown Product' },
        factoryName: bookingData.factoryName || '',
        factoryAddress: bookingData.factoryAddress || '',
        factoryEmail: validatedData.factoryEmail,
        clientName: validatedData.companyName,
        companyName: validatedData.companyName,
        clientEmail,
        inspectorEmail: bookingData.inspectorEmail || null,
        quantity,
        productName: validatedData.productName,
        productDescription: bookingData.productDescription || '',
        destinationCountry: bookingData.destinationCountry || '',
        orderNumber: bookingData.orderNumber || '',
        date: validatedData.date,
        time: validatedData.time,
        notes: bookingData.notes || '',
        useAQL: bookingData.useAQL,
        totalCost: bookingData.totalCost,
        inspectorsNeeded: bookingData.inspectorsNeeded,
        quantityMessage: bookingData.quantityMessage,
        aqlInfo: bookingData.aqlInfo,
        contactPerson: bookingData.factoryName || validatedData.companyName,
        // Add payment details
        paymentStatus: 'completed',
        paymentDetails: {
          orderId: (bookingData as any).paymentDetails?.orderId,
          amount: (bookingData as any).paymentDetails?.amount,
          timestamp: (bookingData as any).paymentDetails?.timestamp,
          status: 'completed'
        }
      }

      // Send email notification
      const notificationUrl = `${API_URL}/notifications/booking-confirmation`
      console.log(`Attempt ${attempt}: Sending notification to:`, notificationUrl)
      console.log('Booking details:', JSON.stringify(bookingDetails, null, 2))

      const controller = new AbortController()
      const timeoutId = setTimeout(() => controller.abort(), 30000) // 30 second timeout

      try {
        const response = await fetch(notificationUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify({
            client_email: clientEmail,
            booking_details: bookingDetails,
            inspector_email: bookingDetails.inspectorEmail
          }),
          signal: controller.signal
        })

        clearTimeout(timeoutId)

        if (!response.ok) {
          const errorText = await response.text()
          let errorMessage = `Failed to send email notifications. Status: ${response.status}`
          
          try {
            const errorData = JSON.parse(errorText)
            errorMessage = `${errorMessage}. Details: ${JSON.stringify(errorData)}`
          } catch {
            errorMessage = `${errorMessage}. Response: ${errorText}`
          }
          
          throw new Error(errorMessage)
        }

        const result = await response.json()
        console.log('Email notification sent successfully:', result)
        return result
      } catch (error) {
        clearTimeout(timeoutId)
        throw error
      }
    } catch (error) {
      lastError = error as Error
      console.error(`Attempt ${attempt} failed:`, error)
      
      if (attempt < MAX_RETRIES) {
        const delay = RETRY_DELAY * attempt
        console.log(`Waiting ${delay}ms before retry...`)
        await sleep(delay)
      }
    }
  }

  console.error('All retry attempts failed')
  throw lastError || new Error('Failed to send email notifications after all retries')
}

export interface CancellationEmailData {
  bookingId: string;
  clientEmail: string;
  factoryEmail: string;
  bookingDetails: {
    attachments: {
      images: Array<{ url: string; description?: string }>;
      documents: Array<{ url: string; name: string; description?: string }>;
    };
    [key: string]: any;
  };
}

export async function sendBookingCancellationEmail(data: CancellationEmailData): Promise<void> {
  if (!API_URL) {
    throw new Error('API_URL is not configured');
  }

  if (!isValidEmail(data.clientEmail)) {
    throw new Error(`Invalid client email: ${data.clientEmail}`);
  }

  if (!isValidEmail(data.factoryEmail)) {
    throw new Error(`Invalid factory email: ${data.factoryEmail}`);
  }

  let lastError: Error | null = null
  for (let attempt = 1; attempt <= MAX_RETRIES; attempt++) {
    try {
      console.log(`Attempt ${attempt} of ${MAX_RETRIES} to send cancellation email`)

      const controller = new AbortController()
      const timeoutId = setTimeout(() => controller.abort(), 30000) // 30 second timeout

      try {
        const response = await fetch(`${API_URL}/notifications/booking-cancellation`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
          signal: controller.signal
        });

        clearTimeout(timeoutId)

        if (!response.ok) {
          throw new Error(`Failed to send cancellation email: ${response.statusText}`);
        }

        return
      } catch (error) {
        clearTimeout(timeoutId)
        throw error
      }
    } catch (error) {
      lastError = error as Error
      console.error(`Attempt ${attempt} failed:`, error)
      
      if (attempt < MAX_RETRIES) {
        const delay = RETRY_DELAY * attempt
        console.log(`Waiting ${delay}ms before retry...`)
        await sleep(delay)
      }
    }
  }

  throw lastError || new Error('Failed to send cancellation email after all retries')
}

export interface NotificationResult {
  status: 'success' | 'skipped' | 'failed';
  message?: string;
}

export async function createBookingNotifications(
  bookingId: string,
  userId: string,
  inspectorId: string | null,
  bookingData: Partial<BookingDetails>
): Promise<NotificationResult> {
  console.log('Creating notifications for booking:', bookingId)

  try {
    // Validate booking data
    if (!bookingData.factoryEmail || !isValidEmail(bookingData.factoryEmail)) {
      return {
        status: 'failed',
        message: `Invalid factory email: ${bookingData.factoryEmail}`
      }
    }

    // Ensure factory email and client email are correctly mapped
    const clientEmail = bookingData.clientEmail || auth.currentUser?.email
    if (!clientEmail || !isValidEmail(clientEmail)) {
      return {
        status: 'failed',
        message: `Invalid client email: ${clientEmail}`
      }
    }

    const normalizedBookingData: Partial<BookingDetails> = {
      ...bookingData,
      factoryEmail: bookingData.factoryEmail,
      clientEmail: clientEmail
    }

    try {
      // Send email notifications first
      const emailResult = await sendEmailNotifications(normalizedBookingData, inspectorId)
      
      // If notifications are disabled, still create in-app notifications but return skipped status
      if (emailResult?.status === 'skipped') {
        // Create in-app notifications
        const notificationsRef = collection(db, 'notifications')
        console.log('Creating in-app notifications only (email notifications disabled)')

        const notifications = [
          {
            userId,
            type: 'booking_created',
            bookingId,
            message: 'Your booking has been created successfully',
            read: false,
            createdAt: new Date()
          },
          {
            userId: 'admin',
            type: 'new_booking',
            bookingId,
            message: 'New booking requires review',
            read: false,
            createdAt: new Date()
          }
        ]

        if (inspectorId) {
          notifications.push({
            userId: inspectorId,
            type: 'booking_assigned',
            bookingId,
            message: 'You have been assigned a new inspection',
            read: false,
            createdAt: new Date()
          })
        }

        await Promise.all(notifications.map(notification => 
          addDoc(notificationsRef, notification)
        ))

        return {
          status: 'skipped',
          message: 'Email notifications are disabled, but in-app notifications were created successfully'
        }
      }

      // Create in-app notifications
      const notificationsRef = collection(db, 'notifications')
      console.log('Creating notifications collection reference')

      const notifications = [
        {
          userId,
          type: 'booking_created',
          bookingId,
          message: 'Your booking has been created successfully',
          read: false,
          createdAt: new Date()
        },
        {
          userId: 'admin',
          type: 'new_booking',
          bookingId,
          message: 'New booking requires review',
          read: false,
          createdAt: new Date()
        }
      ]

      if (inspectorId) {
        notifications.push({
          userId: inspectorId,
          type: 'booking_assigned',
          bookingId,
          message: 'You have been assigned a new inspection',
          read: false,
          createdAt: new Date()
        })
      }

      await Promise.all(notifications.map(notification => 
        addDoc(notificationsRef, notification)
      ))

      console.log('All notifications created successfully')
      return {
        status: 'success',
        message: 'All notifications created and sent successfully'
      }
    } catch (error) {
      console.error('Error creating notifications:', error)
      return {
        status: 'failed',
        message: `Failed to create notifications: ${error instanceof Error ? error.message : String(error)}`
      }
    }
  } catch (error) {
    console.error('Error in createBookingNotifications:', error)
    return {
      status: 'failed',
      message: `Unexpected error: ${error instanceof Error ? error.message : String(error)}`
    }
  }
}