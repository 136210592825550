<template>
  <DashboardLayout>
    <div class="mb-8">
      <h1 class="text-2xl font-semibold text-gray-800 dark:text-white">System Settings</h1>
      <p class="text-gray-600 dark:text-gray-400">Configure system-wide settings</p>
    </div>

    <!-- Loading State -->
    <div v-if="loading" class="max-w-3xl">
      <div class="bg-white dark:bg-dark-surface rounded-xl shadow-sm p-6">
        <div class="animate-pulse space-y-4">
          <div class="h-4 bg-gray-200 dark:bg-gray-700 rounded w-1/4"></div>
          <div class="h-10 bg-gray-200 dark:bg-gray-700 rounded"></div>
          <div class="h-4 bg-gray-200 dark:bg-gray-700 rounded w-1/4"></div>
          <div class="h-10 bg-gray-200 dark:bg-gray-700 rounded"></div>
        </div>
      </div>
    </div>

    <!-- Error State -->
    <div v-else-if="error" class="max-w-3xl">
      <div class="bg-white dark:bg-dark-surface rounded-xl shadow-sm p-6">
        <div class="text-center">
          <p class="text-red-500 mb-4">{{ error }}</p>
          <BaseButton @click="retryLoad" variant="primary">
            Try Again
          </BaseButton>
        </div>
      </div>
    </div>

    <!-- Settings Form -->
    <div v-else class="max-w-3xl">
      <div class="bg-white dark:bg-dark-surface rounded-xl shadow-sm hover:shadow-lg transition-all duration-300">
        <!-- Notification Settings -->
        <div class="p-6 border-b border-gray-100 dark:border-gray-700">
          <h2 class="text-lg font-semibold text-gray-800 dark:text-white mb-4">Notification Settings</h2>
          
          <form @submit.prevent="saveSettings" class="space-y-6">
            <!-- Admin Email -->
            <div>
              <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Admin Email
              </label>
              <input
                v-model="settings.adminEmail"
                type="email"
                class="w-full px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-dark-surface"
                placeholder="admin@example.com"
              />
              <p class="mt-1 text-sm text-gray-500">Email address for receiving booking notifications</p>
            </div>

            <!-- Default Inspector Email -->
            <div>
              <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Default Inspector Email
              </label>
              <input
                v-model="settings.defaultInspectorEmail"
                type="email"
                class="w-full px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-dark-surface"
                placeholder="inspector@example.com"
              />
              <p class="mt-1 text-sm text-gray-500">Default email for new inspection assignments</p>
            </div>

            <!-- Email Notifications Toggle -->
            <div class="flex items-center justify-between">
              <div>
                <h3 class="text-sm font-medium text-gray-700 dark:text-gray-300">Email Notifications</h3>
                <p class="text-sm text-gray-500">Enable/disable email notifications</p>
              </div>
              <label class="relative inline-flex items-center cursor-pointer">
                <input
                  v-model="settings.emailNotificationsEnabled"
                  type="checkbox"
                  class="sr-only peer"
                />
                <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></div>
              </label>
            </div>

            <!-- Save Button -->
            <div class="flex justify-end">
              <BaseButton
                type="submit"
                variant="primary"
                :loading="isSaving"
                :disabled="!isFormValid"
              >
                {{ isSaving ? 'Saving...' : 'Save Settings' }}
              </BaseButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { db, waitForFirestore } from '../firebase'
import { useAuthStore } from '../stores/auth'
import DashboardLayout from '../components/DashboardLayout.vue'
import BaseButton from '../components/BaseButton.vue'

const authStore = useAuthStore()
interface NotificationSettings {
  adminEmail: string;
  defaultInspectorEmail: string;
  emailNotificationsEnabled: boolean;
}

const settings = ref<NotificationSettings>({
  adminEmail: '',
  defaultInspectorEmail: '',
  emailNotificationsEnabled: true
})

const loading = ref(true)
const error = ref<string | null>(null)
const isSaving = ref(false)

const isFormValid = computed(() => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(settings.value.adminEmail) &&
         emailRegex.test(settings.value.defaultInspectorEmail)
})

async function loadSettings() {
  loading.value = true
  error.value = null

  try {
    // Ensure Firestore is ready
    await waitForFirestore()

    // Wait for auth to be initialized
    if (!authStore.initialized) {
      await authStore.checkAuth()
    }

    // Check if user is authenticated and is admin
    if (!authStore.isAuthenticated) {
      throw new Error('Please log in to access settings')
    }
    
    if (!authStore.isAdmin) {
      throw new Error('You do not have permission to access settings')
    }

    const settingsDoc = await getDoc(doc(db, 'settings', 'notifications'))
    if (settingsDoc.exists()) {
      const data = settingsDoc.data()
      settings.value = {
        adminEmail: data?.adminEmail || '',
        defaultInspectorEmail: data?.defaultInspectorEmail || '',
        emailNotificationsEnabled: data?.emailNotificationsEnabled ?? true
      }
    } else {
      // Create default settings if they don't exist
      const defaultSettings = {
        adminEmail: 'book@inspectaman.com',
        defaultInspectorEmail: 'payrmg@gmail.com',
        emailNotificationsEnabled: true
      }
      await setDoc(doc(db, 'settings', 'notifications'), defaultSettings)
      settings.value = defaultSettings
    }
  } catch (err: any) {
    console.error('Error loading settings:', err)
    error.value = err.message || 'Failed to load settings'
  } finally {
    loading.value = false
  }
}

async function saveSettings() {
  if (!isFormValid.value) {
    error.value = 'Please enter valid email addresses'
    return
  }

  try {
    isSaving.value = true
    error.value = null

    // Check if user is authenticated and is admin
    if (!authStore.isAuthenticated) {
      throw new Error('Please log in to save settings')
    }
    
    if (!authStore.isAdmin) {
      throw new Error('You do not have permission to save settings')
    }

    await setDoc(doc(db, 'settings', 'notifications'), settings.value)
    alert('Settings saved successfully')
  } catch (err: any) {
    console.error('Error saving settings:', err)
    error.value = err.message || 'Failed to save settings'
  } finally {
    isSaving.value = false
  }
}

async function retryLoad() {
  await loadSettings()
}

onMounted(async () => {
  await loadSettings()
})
</script>