<template>
  <div class="relative">
    <!-- Notification Bell Button -->
    <button 
      @click="toggleDropdown"
      class="relative p-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-white transition-colors duration-300"
    >
      <svg 
        class="w-6 h-6" 
        fill="none" 
        viewBox="0 0 24 24" 
        stroke="currentColor"
      >
        <path 
          stroke-linecap="round" 
          stroke-linejoin="round" 
          stroke-width="2" 
          d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
        />
      </svg>
      
      <!-- Unread Badge -->
      <span 
        v-if="unreadCount > 0"
        class="absolute -top-1 -right-1 bg-red-500 text-white text-xs font-bold rounded-full h-5 w-5 flex items-center justify-center"
      >
        {{ unreadCount }}
      </span>
    </button>

    <!-- Notifications Dropdown -->
    <div 
      v-if="isOpen"
      class="absolute right-0 mt-2 w-80 bg-white dark:bg-dark-surface rounded-xl shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
    >
      <div class="p-4 border-b border-gray-100 dark:border-gray-700">
        <h3 class="text-lg font-semibold text-gray-800 dark:text-white">Notifications</h3>
      </div>

      <div class="max-h-96 overflow-y-auto">
        <div v-if="notifications.length === 0" class="p-4 text-center text-gray-500">
          No notifications
        </div>

        <div 
          v-for="notification in notifications" 
          :key="notification.id"
          :class="[
            'p-4 hover:bg-gray-50 dark:hover:bg-gray-800/50 cursor-pointer transition-colors duration-300',
            !notification.read ? 'bg-blue-50 dark:bg-blue-900/20' : ''
          ]"
          @click="handleNotificationClick(notification)"
        >
          <div class="flex items-start">
            <!-- Icon based on notification type -->
            <div 
              class="flex-shrink-0 w-8 h-8 rounded-lg flex items-center justify-center"
              :class="getNotificationTypeClass(notification.type)"
            >
              <svg class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path 
                  v-if="notification.type === 'booking_created'"
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                  stroke-width="2" 
                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                />
                <path 
                  v-else-if="notification.type === 'booking_assigned'"
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                  stroke-width="2" 
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
                <path 
                  v-else
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                  stroke-width="2" 
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>

            <div class="ml-3 flex-1">
              <p class="text-sm font-medium text-gray-800 dark:text-white">
                {{ notification.message }}
              </p>
              <p class="mt-1 text-xs text-gray-500">
                {{ formatDate(notification.createdAt) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from '../stores/auth'
import { getUserNotifications, markNotificationAsRead, type Notification } from '../services/notifications'

const router = useRouter()
const authStore = useAuthStore()
const isOpen = ref(false)
const notifications = ref<Notification[]>([])
const refreshInterval = ref<number>()

const unreadCount = computed(() => {
  return notifications.value.filter(n => !n.read).length
})

async function fetchNotifications() {
  if (!authStore.user?.uid) return
  
  try {
    notifications.value = await getUserNotifications(authStore.user.uid)
  } catch (error) {
    console.error('Error fetching notifications:', error)
  }
}

function toggleDropdown() {
  isOpen.value = !isOpen.value
}

function formatDate(date: Date): string {
  const now = new Date()
  const diff = now.getTime() - date.getTime()
  const minutes = Math.floor(diff / 60000)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  if (minutes < 60) {
    return `${minutes} minutes ago`
  } else if (hours < 24) {
    return `${hours} hours ago`
  } else {
    return `${days} days ago`
  }
}

function getNotificationTypeClass(type: string): string {
  switch (type) {
    case 'booking_created':
      return 'bg-green-100 dark:bg-green-900/20 text-green-600 dark:text-green-400'
    case 'booking_assigned':
      return 'bg-blue-100 dark:bg-blue-900/20 text-blue-600 dark:text-blue-400'
    default:
      return 'bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-400'
  }
}

async function handleNotificationClick(notification: Notification) {
  // Mark as read
  if (!notification.read) {
    await markNotificationAsRead(notification.id)
    const index = notifications.value.findIndex(n => n.id === notification.id)
    if (index !== -1) {
      notifications.value[index] = { ...notification, read: true }
    }
  }

  // Navigate based on notification type
  if (notification.type === 'booking_created' || notification.type === 'booking_assigned') {
    router.push({
      path: '/booking-confirmation',
      query: { id: notification.bookingId }
    })
  }

  isOpen.value = false
}

// Click outside to close
function handleClickOutside(event: MouseEvent) {
  const target = event.target as HTMLElement
  if (!target.closest('.notification-dropdown')) {
    isOpen.value = false
  }
}

onMounted(() => {
  fetchNotifications()
  // Refresh notifications every 5 minutes
  refreshInterval.value = window.setInterval(fetchNotifications, 300000)
  document.addEventListener('click', handleClickOutside)
})

onUnmounted(() => {
  if (refreshInterval.value) {
    clearInterval(refreshInterval.value)
  }
  document.removeEventListener('click', handleClickOutside)
})
</script>