// src/composables/useAuth.ts
import { ref, computed } from 'vue'
import { useCurrentUser, useFirebaseAuth } from 'vuefire'
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  signOut as firebaseSignOut,
  UserCredential
} from 'firebase/auth'
import { googleAuthProvider } from '../providers/auth'

export function useAuth() {
  const auth = useFirebaseAuth()
  const user = useCurrentUser()
  const loading = ref(false)
  const error = ref<Error | null>(null)

  const isAuthenticated = computed(() => !!user.value)

  async function signInWithGoogle(): Promise<UserCredential> {
    try {
      loading.value = true
      error.value = null
      if (!auth) throw new Error('Auth not initialized')
      return await signInWithPopup(auth, googleAuthProvider)
    } catch (err: any) {
      error.value = err
      throw err
    } finally {
      loading.value = false
    }
  }

  async function signInWithEmail(email: string, password: string): Promise<UserCredential> {
    try {
      loading.value = true
      error.value = null
      if (!auth) throw new Error('Auth not initialized')
      return await signInWithEmailAndPassword(auth, email, password)
    } catch (err: any) {
      error.value = err
      throw err
    } finally {
      loading.value = false
    }
  }

  async function signUp(email: string, password: string): Promise<UserCredential> {
    try {
      loading.value = true
      error.value = null
      if (!auth) throw new Error('Auth not initialized')
      return await createUserWithEmailAndPassword(auth, email, password)
    } catch (err: any) {
      error.value = err
      throw err
    } finally {
      loading.value = false
    }
  }

  async function signOut(): Promise<void> {
    try {
      loading.value = true
      error.value = null
      if (!auth) throw new Error('Auth not initialized')
      await firebaseSignOut(auth)
    } catch (err: any) {
      error.value = err
      throw err
    } finally {
      loading.value = false
    }
  }

  return {
    user,
    loading,
    error,
    isAuthenticated,
    signInWithGoogle,
    signInWithEmail,
    signUp,
    signOut
  }
}