import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { useAuth } from '@/composables/useAuth'
import { onAuthStateChanged, sendEmailVerification, User, UserCredential } from 'firebase/auth'
import { auth } from '@/firebase'
import { doc, setDoc, getDoc } from 'firebase/firestore'
import { db, waitForFirestore } from '@/firebase'

export type UserRole = 'admin' | 'inspector' | 'user'

interface UserProfile {
  name: string
  email: string
  role: UserRole
  createdAt: string
  emailVerified: boolean
  companyName?: string
  businessType?: string
  salesPlatform?: string
  billingName?: string
  billingEmail?: string
  billingAddress?: string
  paymentMethod?: string
}

class AuthError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'AuthError'
  }
}

export const useAuthStore = defineStore('auth', () => {
  const { 
    user,
    loading,
    error,
    isAuthenticated,
    signInWithEmail,
    signInWithGoogle,
    signUp,
    signOut
  } = useAuth()

  // Additional auth state
  const returnUrl = ref<string | null>(null)
  const initialized = ref(false)
  const userProfile = ref<UserProfile | null>(null)
  const initializationPromise = ref<Promise<void> | null>(null)
  const firestoreReady = ref(false)

  // Computed
  const userRole = computed<UserRole>(() => {
    if (!user.value) return 'user'
    // Get user role from email domain
    const email = user.value.email
    if (!email) return 'user'
    if (email === 'book@inspectaman.com' || email.endsWith('@admin.inspectaman.com')) return 'admin'
    if (email.endsWith('@inspector.inspectaman.com')) return 'inspector'
    return userProfile.value?.role || 'user'
  })

  const isAdmin = computed(() => userRole.value === 'admin')
  const isInspector = computed(() => userRole.value === 'inspector')
  const isEmailVerified = computed(() => user.value?.emailVerified || false)

  // Firebase error handling
  const handleFirebaseError = (error: any): AuthError => {
    console.error('Firebase error:', error)
    const errorCode = error.code
    let message: string
    switch (errorCode) {
     case 'auth/email-already-in-use':
       message = 'This email is already registered. Please try logging in.'
       break
     case 'auth/invalid-email':
       message = 'Invalid email address.'
       break
     case 'auth/operation-not-allowed':
       message = 'Email/password accounts are not enabled. Please contact support.'
       break
     case 'auth/weak-password':
       message = 'Password should be at least 8 characters long.'
       break
     case 'auth/user-disabled':
       message = 'This account has been disabled. Please contact support.'
       break
     case 'auth/user-not-found':
       message = 'No account found with this email.'
       break
     case 'auth/wrong-password':
       message = 'Invalid password.'
       break
     case 'auth/network-request-failed':
       message = 'Network error. Please check your connection and try again.'
       break
     case 'auth/too-many-requests':
       message = 'Too many failed attempts. Please try again later.'
       break
     case 'auth/popup-closed-by-user':
       message = 'Google sign-in was cancelled. Please try again.'
       break
     case 'auth/popup-blocked':
       message = 'Google sign-in popup was blocked. Please allow popups and try again.'
       break
     case 'auth/unauthorized-domain':
       message = 'This domain is not authorized for Google sign-in. Please use the official app URL.'
       break
      default:
        message = error.message || 'An error occurred. Please try again.'
    }
    return new AuthError(message)
  }

  // Actions
  async function login(email: string, password: string): Promise<UserCredential> {
    try {
      loading.value = true
      error.value = null
      const userCredential = await signInWithEmail(email, password)
      await waitForFirestore() // Wait for Firestore to be ready
      await fetchUserProfile()
      return userCredential
    } catch (err: any) {
      const authError = handleFirebaseError(err)
      error.value = authError
      throw authError
    } finally {
      loading.value = false
    }
  }

  async function loginWithGoogle(): Promise<UserCredential> {
    try {
      loading.value = true
      error.value = null
      const userCredential = await signInWithGoogle()
      await waitForFirestore() // Wait for Firestore to be ready
      
      // Check if user profile exists
      const profile = await fetchUserProfile()
      
      // If no profile exists, create one using Google account info
      if (!profile && userCredential.user) {
        await createUserProfile(userCredential.user.uid, {
          name: userCredential.user.displayName || 'User',
          email: userCredential.user.email || '',
          role: 'user', // Always set role to 'user' for Google sign-in
          createdAt: new Date().toISOString(),
          emailVerified: userCredential.user.emailVerified
        })
      }
      
      return userCredential
    } catch (err: any) {
      const authError = handleFirebaseError(err)
      error.value = authError
      throw authError
    } finally {
      loading.value = false
    }
  }

  async function register(email: string, password: string, name: string): Promise<{ user: User }> {
    try {
      loading.value = true
      error.value = null
      await waitForFirestore() // Wait for Firestore to be ready

      // Create new user
      const userCredential = await signUp(email, password)
      
      // Create user profile with 'user' role
      await createUserProfile(userCredential.user.uid, {
        name,
        email,
        role: 'user', // Always set role to 'user' for regular registration
        createdAt: new Date().toISOString(),
        emailVerified: false
      })

      // Send verification email
      await sendEmailVerification(userCredential.user)

      return { user: userCredential.user }
    } catch (err: any) {
      const authError = handleFirebaseError(err)
      error.value = authError
      throw authError
    } finally {
      loading.value = false
    }
  }

  async function logout(): Promise<void> {
    try {
      loading.value = true
      error.value = null

      // Clear all state first
      userProfile.value = null
      initialized.value = false
      firestoreReady.value = false

      // Disable Firestore network to clear pending operations
      try {
        const { disableNetwork } = await import('firebase/firestore')
        await disableNetwork(db)
      } catch (networkError) {
        console.warn('Error disabling Firestore network:', networkError)
      }

      // Sign out from Firebase
      await signOut()

      // Re-enable network for next user
      try {
        const { enableNetwork } = await import('firebase/firestore')
        await enableNetwork(db)
      } catch (networkError) {
        console.warn('Error re-enabling Firestore network:', networkError)
      }
    } catch (err: any) {
      const authError = handleFirebaseError(err)
      error.value = authError
      throw authError
    } finally {
      loading.value = false
    }
  }

  async function createUserProfile(uid: string, profile: UserProfile): Promise<void> {
    try {
      await waitForFirestore() // Wait for Firestore to be ready
      const docRef = doc(db, 'users', uid)
      await setDoc(docRef, profile)
      userProfile.value = profile
    } catch (error: any) {
      console.error('Error creating user profile:', error)
      throw new AuthError('Failed to create user profile')
    }
  }

  async function fetchUserProfile(): Promise<UserProfile | null> {
    if (!user.value) return null

    try {
      await waitForFirestore() // Wait for Firestore to be ready
      const docRef = doc(db, 'users', user.value.uid)
      const docSnap = await getDoc(docRef)
      
      if (docSnap.exists()) {
        userProfile.value = docSnap.data() as UserProfile
        // Update email verification status in profile
        if (user.value.emailVerified !== userProfile.value.emailVerified) {
          try {
            await setDoc(docRef, {
              ...userProfile.value,
              emailVerified: user.value.emailVerified
            }, { merge: true })
          } catch (updateError) {
            console.warn('Failed to update email verification status:', updateError)
            // Continue with existing profile even if update fails
          }
        }
        return userProfile.value
      }

      // If offline and profile exists in memory, use that
      if (userProfile.value) {
        console.warn('Using cached user profile due to offline state')
        return userProfile.value
      }

      // Create a basic profile if offline and no profile exists
      if (!navigator.onLine) {
        const basicProfile: UserProfile = {
          name: user.value.displayName || 'User',
          email: user.value.email || '',
          role: 'user',
          createdAt: new Date().toISOString(),
          emailVerified: user.value.emailVerified
        }
        userProfile.value = basicProfile
        console.warn('Created temporary profile due to offline state')
        return basicProfile
      }

      return null
    } catch (error) {
      console.error('Error fetching user profile:', error)
      // If offline and profile exists in memory, use that
      if (!navigator.onLine && userProfile.value) {
        console.warn('Using cached user profile due to offline state')
        return userProfile.value
      }
      return null
    }
  }

  // Check and monitor auth state
  async function checkAuth(): Promise<void> {
    // Return existing initialization if it's in progress
    if (initializationPromise.value) {
      return initializationPromise.value
    }

    // Return immediately if already initialized
    if (initialized.value) return

    initializationPromise.value = new Promise<void>(async (resolve) => {
      try {
        // Wait for Firestore to be ready first
        await waitForFirestore()
        firestoreReady.value = true

        // Keep track of the auth state listener
        let authUnsubscribe: (() => void) | null = null

        // Set up persistent auth state listener
        authUnsubscribe = onAuthStateChanged(auth, async (firebaseUser: User | null) => {
          try {
            if (firebaseUser) {
              // User is signed in
              user.value = firebaseUser
              
              // Ensure Firestore is ready before fetching profile
              if (!firestoreReady.value) {
                await waitForFirestore()
                firestoreReady.value = true
              }
              
              await fetchUserProfile()
            } else {
              // User is signed out
              user.value = null
              userProfile.value = null
              firestoreReady.value = false
            }
          } catch (error) {
            console.error('Error during auth state change:', error)
          } finally {
            // Only resolve the initialization promise once
            if (!initialized.value) {
              initialized.value = true
              resolve()
              initializationPromise.value = null
            }
          }
        })

        // Clean up listener on window unload
        window.addEventListener('unload', () => {
          if (authUnsubscribe) {
            authUnsubscribe()
          }
        })
      } catch (error) {
        console.error('Error during auth initialization:', error)
        initialized.value = true
        resolve()
        initializationPromise.value = null
      }
    })

    return initializationPromise.value
  }

  return {
    // State
    user,
    loading,
    error,
    returnUrl,
    initialized,
    userProfile,
    firestoreReady,

    // Computed
    isAuthenticated,
    userRole,
    isAdmin,
    isInspector,
    isEmailVerified,

    // Actions
    login,
    loginWithGoogle,
    register,
    logout,
    checkAuth,
    createUserProfile,
    fetchUserProfile
  }
})