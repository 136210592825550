<template>
  <nav class="fixed top-0 left-0 right-0 bg-white/90 dark:bg-dark-surface/90 backdrop-blur-lg z-50 border-b border-gray-100">
    <div class="max-w-7xl mx-auto px-8 py-4">
      <div class="flex items-center justify-between">
        <router-link to="/" class="flex items-center space-x-2">
          <span class="text-2xl font-bold tracking-wide">
            <span class="text-primary">INSPECTA</span>
            <span class="text-gray-600 dark:text-gray-400">MAN</span>
          </span>
        </router-link>
        <div class="flex items-center space-x-8">
          <template v-if="!authStore.isAuthenticated">
            <router-link to="/auth/login" class="text-sm text-gray-600 hover:text-primary transition-colors">Login</router-link>
            <router-link to="/auth/register" class="text-sm text-gray-600 hover:text-primary transition-colors">Register</router-link>
          </template>
          <template v-else>
            <div class="flex items-center space-x-4">
              <span class="text-sm text-gray-600">Welcome {{ userProfile?.name || 'User' }}!</span>
              <NotificationBell />
              <button @click="handleLogout" class="text-sm text-gray-600 hover:text-primary transition-colors">Logout</button>
            </div>
          </template>
          <BaseButton variant="primary" size="sm" @click="$router.push('/booking')">Book Now</BaseButton>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { useAuthStore } from '../stores/auth'
import BaseButton from './BaseButton.vue'
import NotificationBell from './NotificationBell.vue'
import { useRouter } from 'vue-router'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const router = useRouter()
const route = useRoute()
const authStore = useAuthStore()
const userProfile = computed(() => authStore.userProfile)

const isActiveRoute = (path: string) => {
  return route.path === path
}

const handleLogout = async () => {
  await authStore.logout()
  router.push('/auth/login')
}
</script>