<template>
  <DashboardLayout>
    <!-- Header -->
    <div class="mb-8">
      <h1 class="text-2xl font-semibold text-gray-800 dark:text-white">User List</h1>
      <p class="text-gray-600 dark:text-gray-400">View and manage system users</p>
    </div>

    <!-- User List Table -->
    <div class="bg-white dark:bg-dark-surface rounded-xl shadow-sm">
      <div class="p-6">
        <!-- Search and Filter -->
        <div class="flex justify-between items-center mb-6">
          <div class="flex items-center space-x-4">
            <input
              type="text"
              v-model="searchQuery"
              placeholder="Search users..."
              class="px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-dark-surface"
            />
            <select
              v-model="filterRole"
              class="px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-dark-surface"
            >
              <option value="">All Roles</option>
              <option value="user">User</option>
              <option value="inspector">Inspector</option>
              <option value="admin">Admin</option>
            </select>
          </div>
          <button
            @click="retryFetch"
            :disabled="loading"
            class="px-4 py-2 text-sm font-medium text-white bg-primary rounded-lg hover:bg-primary-dark disabled:opacity-50"
          >
            <span v-if="loading">Loading...</span>
            <span v-else>Refresh</span>
          </button>
        </div>

        <!-- Loading State -->
        <div v-if="loading" class="text-center py-8">
          <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-primary mx-auto"></div>
          <p class="mt-4 text-gray-600 dark:text-gray-400">Loading users...</p>
        </div>

        <!-- Error State -->
        <div v-else-if="error" class="text-center py-8">
          <div class="text-red-500 mb-4">{{ error }}</div>
          <button
            @click="retryFetch"
            class="px-4 py-2 text-sm font-medium text-white bg-primary rounded-lg hover:bg-primary-dark"
          >
            Try Again
          </button>
        </div>

        <!-- Users Table -->
        <div v-else class="overflow-x-auto">
          <table class="min-w-full">
            <thead>
              <tr class="border-b border-gray-200 dark:border-gray-700">
                <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Name</th>
                <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Email</th>
                <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Role</th>
                <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Total Spent</th>
                <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Status</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 dark:divide-gray-700">
              <tr v-for="user in paginatedUsers" :key="user.id" class="hover:bg-gray-50 dark:hover:bg-gray-800/50">
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="h-10 w-10 rounded-full bg-primary/10 flex items-center justify-center">
                      <span class="text-primary text-sm font-medium">{{ getUserInitials(user.name) }}</span>
                    </div>
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900 dark:text-white">{{ user.name }}</div>
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="text-sm text-gray-900 dark:text-white">{{ user.email }}</div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <span :class="[
                    'px-2 py-1 text-xs rounded-full',
                    user.role === 'admin' ? 'bg-purple-100 text-purple-800' :
                    user.role === 'inspector' ? 'bg-blue-100 text-blue-800' :
                    'bg-green-100 text-green-800'
                  ]">
                    {{ user.role }}
                  </span>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="text-sm text-gray-900 dark:text-white">${{ Number(user.totalSpent || 0).toFixed(2) }}</div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <span :class="[
                    'px-2 py-1 text-xs rounded-full',
                    user.emailVerified ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                  ]">
                    {{ user.emailVerified ? 'Verified' : 'Pending' }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- No Results -->
          <div v-if="filteredUsers.length === 0" class="text-center py-8">
            <p class="text-gray-600 dark:text-gray-400">No users found</p>
          </div>
        </div>

        <!-- Pagination -->
        <div v-if="!loading && !error && filteredUsers.length > 0" class="flex justify-between items-center mt-6 pt-4 border-t border-gray-100 dark:border-gray-700">
          <button
            @click="currentPage--"
            :disabled="currentPage === 1"
            class="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Previous
          </button>
          <span class="text-sm text-gray-600 dark:text-gray-400">
            Page {{ currentPage }} of {{ totalPages }}
          </span>
          <button
            @click="currentPage++"
            :disabled="currentPage >= totalPages"
            class="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { collection, getDocs, query, where, orderBy, limit, DocumentData, QueryDocumentSnapshot, getDoc, doc } from 'firebase/firestore'
import { db, auth } from '../firebase'
import DashboardLayout from '../components/DashboardLayout.vue'

const users = ref<any[]>([])
const searchQuery = ref('')
const filterRole = ref('')
const currentPage = ref(1)
const itemsPerPage = 10
const loading = ref(false)
const error = ref<string | null>(null)

// Filter users based on search query and role
const filteredUsers = computed(() => {
  let filtered = users.value

  if (searchQuery.value) {
    const search = searchQuery.value.toLowerCase()
    filtered = filtered.filter(user => 
      user.name?.toLowerCase().includes(search) ||
      user.email?.toLowerCase().includes(search)
    )
  }

  if (filterRole.value) {
    filtered = filtered.filter(user => user.role === filterRole.value)
  }

  return filtered
})

// Calculate total pages for pagination
const totalPages = computed(() => Math.ceil(filteredUsers.value.length / itemsPerPage))

// Get paginated users
const paginatedUsers = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage
  const end = start + itemsPerPage
  return filteredUsers.value.slice(start, end)
})

// Get user initials for avatar
function getUserInitials(name: string): string {
  if (!name) return '?'
  return name.split(' ')
    .map(word => word[0])
    .join('')
    .toUpperCase()
    .slice(0, 2)
}

// Fetch total spent for a user
async function fetchUserTotalSpent(userId: string): Promise<number> {
  try {
    const bookingsRef = collection(db, 'bookings')
    const userBookingsQuery = query(bookingsRef, where('userId', '==', userId))
    const bookingsSnapshot = await getDocs(userBookingsQuery)
    
    return bookingsSnapshot.docs.reduce((total, doc) => {
      const booking = doc.data()
      return total + Number(booking.totalAmount || booking.totalCost || 0)
    }, 0)
  } catch (err) {
    console.error(`Error fetching total spent for user ${userId}:`, err)
    return 0
  }
}

// Enhanced fetch users with better error handling
async function fetchUsers() {
  if (!auth.currentUser) {
    error.value = 'Authentication required'
    return
  }

  loading.value = true
  error.value = null
  users.value = []

  try {
    const usersRef = collection(db, 'users')
    const usersSnapshot = await getDocs(usersRef)
    
    // Process users in smaller batches to avoid timeout
    const batchSize = 10
    const usersList = []
    
    for (let i = 0; i < usersSnapshot.docs.length; i += batchSize) {
      const batch = usersSnapshot.docs.slice(i, i + batchSize)
      const batchPromises = batch.map(async (doc) => {
        const userData = doc.data()
        const totalSpent = await fetchUserTotalSpent(doc.id)
        
        return {
          id: doc.id,
          name: userData.name,
          email: userData.email,
          role: userData.role || 'user',
          emailVerified: userData.emailVerified,
          totalSpent
        }
      })
      
      const batchResults = await Promise.all(batchPromises)
      usersList.push(...batchResults)
    }

    users.value = usersList
    error.value = null
  } catch (err: any) {
    console.error('Error fetching users:', err)
    
    if (err.code === 'permission-denied') {
      error.value = 'You do not have permission to view users. Please check your access rights.'
    } else if (err.code === 'unavailable') {
      error.value = 'Service is currently unavailable. Please try again later.'
    } else {
      error.value = 'Failed to load users. Please check your connection and try again.'
    }
  } finally {
    loading.value = false
  }
}

// Function to manually retry fetching
async function retryFetch() {
  await fetchUsers()
}

// Initialize data
onMounted(() => {
  if (auth.currentUser) {
    fetchUsers()
  } else {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchUsers()
      }
      unsubscribe()
    })
  }
})
</script>