// src/providers/auth.ts
import { GoogleAuthProvider } from 'firebase/auth'

const googleAuthProvider = new GoogleAuthProvider()

// Add required scopes
googleAuthProvider.addScope('profile')
googleAuthProvider.addScope('email')

// Set custom parameters
googleAuthProvider.setCustomParameters({
  prompt: 'select_account'
})

export { googleAuthProvider }