<!-- Previous template code remains the same until the Settings button -->
<template>
  <DashboardLayout>
    <!-- Welcome Section -->
    <div class="mb-8">
      <h1 class="text-2xl font-semibold text-gray-800 dark:text-white">Admin Dashboard</h1>
      <p class="text-gray-600 dark:text-gray-400">Manage your inspection system</p>
    </div>

    <!-- Stats Overview -->
    <div class="grid grid-cols-1 md:grid-cols-4 gap-8 mb-12">
      <StatsCard title="Total Users" :value="stats.totalUsers" icon-bg-class="bg-primary/10">
        <template #icon>
          <svg class="w-6 h-6 text-primary" viewBox="0 0 24 24" fill="none">
            <path d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </template>
        <template #trend>
          <p class="ml-2 text-sm text-green-500">+12.4%</p>
        </template>
      </StatsCard>

      <StatsCard title="Active Inspectors" :value="stats.activeInspectors" icon-bg-class="bg-yellow-50">
        <template #icon>
          <svg class="w-6 h-6 text-yellow-500" viewBox="0 0 24 24" fill="none">
            <path d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </template>
        <template #trend>
          <p class="ml-2 text-sm text-yellow-500">+5.2%</p>
        </template>
      </StatsCard>

      <StatsCard title="Total Revenue" :value="'$' + stats.totalRevenue" icon-bg-class="bg-green-50">
        <template #icon>
          <svg class="w-6 h-6 text-green-500" viewBox="0 0 24 24" fill="none">
            <path d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </template>
        <template #trend>
          <p class="ml-2 text-sm text-green-500">+8.1%</p>
        </template>
      </StatsCard>

      <StatsCard title="Pending Approvals" :value="stats.pendingApprovals" icon-bg-class="bg-blue-50">
        <template #icon>
          <svg class="w-6 h-6 text-blue-500" viewBox="0 0 24 24" fill="none">
            <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </template>
        <template #trend>
          <p class="ml-2 text-sm text-blue-500">-2.3%</p>
        </template>
      </StatsCard>
    </div>

    <!-- Main Content Grid -->
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-8 mb-8">
      <!-- Recent Bookings -->
      <div class="lg:col-span-2">
        <div class="rounded-xl bg-white dark:bg-dark-surface shadow-sm hover:shadow-lg transition-all duration-300">
          <div class="flex items-center justify-between p-6 border-b border-gray-100 dark:border-gray-700">
            <h2 class="text-lg font-semibold text-gray-800 dark:text-white">Recent Bookings</h2>
            <div class="flex space-x-2">
              <select v-model="filterStatus" class="rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-dark-surface text-sm">
                <option value="">All Status</option>
                <option value="pending">Pending</option>
                <option value="confirmed">Confirmed</option>
                <option value="completed">Completed</option>
              </select>
            </div>
          </div>
          <div class="p-6">
            <div v-if="bookings.length > 0" class="space-y-4">
              <div v-for="booking in paginatedBookings" :key="booking.id" class="p-4 bg-gray-50 dark:bg-gray-800/50 rounded-xl">
                <div class="flex justify-between items-start">
                  <div>
                    <h3 class="font-medium text-gray-800 dark:text-white">{{ formatServiceType(booking.selectedProduct?.name) }}</h3>
                    <p class="text-sm text-gray-600 dark:text-gray-400">{{ booking.factoryName }}</p>
                    <p class="text-sm text-gray-500">{{ formatDateTime(booking.date, booking.time) }}</p>
                    <div class="mt-2">
                      <span :class="[
                        'px-2 py-1 text-xs rounded-full',
                        booking.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                        booking.status === 'confirmed' ? 'bg-green-100 text-green-800' :
                        'bg-gray-100 text-gray-800'
                      ]">
                        {{ booking.status }}
                      </span>
                    </div>
                  </div>
                  <div class="flex flex-col space-y-2">
                    <router-link :to="{ path: '/booking-confirmation', query: { id: booking.id }}" class="text-primary hover:text-primary-dark">
                      View Details
                    </router-link>
                    <button v-if="booking.status === 'pending'" @click="assignInspector(booking.id)" class="text-sm text-green-600 hover:text-green-700">
                      Assign Inspector
                    </button>
                  </div>
                </div>
              </div>
              <!-- Pagination Controls -->
              <div class="flex justify-between items-center mt-6 pt-4 border-t border-gray-100 dark:border-gray-700">
                <button
                  @click="currentPage--"
                  :disabled="currentPage === 1"
                  class="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Previous
                </button>
                <span class="text-sm text-gray-600 dark:text-gray-400">
                  Page {{ currentPage }} of {{ totalPages }}
                </span>
                <button
                  @click="currentPage++"
                  :disabled="currentPage >= totalPages"
                  class="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Next
                </button>
              </div>
            </div>
            <div v-else class="text-center py-8">
              <svg class="w-12 h-12 text-gray-300 mx-auto mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
              </svg>
              <p class="text-gray-500 dark:text-gray-400">No bookings found</p>
              <p class="text-sm text-gray-400 dark:text-gray-500 mt-1">Bookings will appear here</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Quick Actions -->
      <div class="space-y-6">
        <div class="rounded-xl bg-white dark:bg-dark-surface shadow-sm hover:shadow-lg transition-all duration-300">
          <div class="flex items-center justify-between p-6 border-b border-gray-100 dark:border-gray-700">
            <h2 class="text-lg font-semibold text-gray-800 dark:text-white">Quick Actions</h2>
          </div>
          <div class="p-6 space-y-4">
            <BaseButton variant="primary" block class="flex items-center justify-center space-x-3">
              <div class="w-8 h-8 rounded-lg bg-white/20 flex items-center justify-center backdrop-blur-sm">
                <svg class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
                </svg>
              </div>
              <span>Add Inspector</span>
            </BaseButton>

            <BaseButton block class="flex items-center justify-center space-x-3">
              <div class="w-8 h-8 rounded-lg bg-gray-100 dark:bg-dark-surface flex items-center justify-center">
                <svg class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                </svg>
              </div>
              <span>View Reports</span>
            </BaseButton>

            <router-link to="/admin/users" custom v-slot="{ navigate }">
              <BaseButton block @click="navigate" class="flex items-center justify-center space-x-3">
                <div class="w-8 h-8 rounded-lg bg-gray-100 dark:bg-dark-surface flex items-center justify-center">
                  <svg class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                </div>
                <span>User List</span>
              </BaseButton>
            </router-link>

            <router-link to="/admin/products" custom v-slot="{ navigate }">
              <BaseButton block @click="navigate" class="flex items-center justify-center space-x-3">
                <div class="w-8 h-8 rounded-lg bg-gray-100 dark:bg-dark-surface flex items-center justify-center">
                  <svg class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
                  </svg>
                </div>
                <span>Manage Products</span>
              </BaseButton>
            </router-link>

            <router-link to="/admin/settings" custom v-slot="{ navigate }">
              <BaseButton block @click="navigate" class="flex items-center justify-center space-x-3">
                <div class="w-8 h-8 rounded-lg bg-gray-100 dark:bg-dark-surface flex items-center justify-center">
                  <svg class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                </div>
                <span>Settings</span>
              </BaseButton>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, computed } from 'vue'
import { collection, query, orderBy, getDocs, where } from 'firebase/firestore'
import { db } from '../firebase'
import DashboardLayout from '../components/DashboardLayout.vue'
import BaseButton from '../components/BaseButton.vue'
import StatsCard from '../components/StatsCard.vue'

const stats = ref({
  totalUsers: 256,
  activeInspectors: 42,
  totalRevenue: 125430,
  pendingApprovals: 15,
})

const bookings = ref<any[]>([])
const filterStatus = ref('')
const currentPage = ref(1)
const itemsPerPage = 10

const totalPages = computed(() => Math.ceil(bookings.value.length / itemsPerPage))

const paginatedBookings = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage
  const end = start + itemsPerPage
  return bookings.value.slice(start, end)
})

// Service type formatting
const serviceTypeLabels = {
  factory_audit: 'Factory Audit',
  during_production: 'During Production Inspection',
  pre_shipment: 'Pre-shipment Inspection',
  container_loading: 'Container Loading Inspection'
} as const

function formatServiceType(type: string): string {
  return serviceTypeLabels[type as keyof typeof serviceTypeLabels] || type
}

function formatDateTime(date: string, time: string): string {
  if (!date || !time) return ''
  const dateObj = new Date(`${date}T${time}`)
  return dateObj.toLocaleString()
}

async function fetchBookings() {
  try {
    const bookingsRef = collection(db, 'bookings')
    let q = query(bookingsRef, orderBy('createdAt', 'desc'))
    
    if (filterStatus.value) {
      q = query(q, where('status', '==', filterStatus.value))
    }
    
    const querySnapshot = await getDocs(q)
    bookings.value = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }))
  } catch (error) {
    console.error('Error fetching bookings:', error)
  }
}

async function assignInspector(bookingId: string) {
  // TODO: Implement inspector assignment functionality
  console.log('Assigning inspector to booking:', bookingId)
}

// Watch for filter changes
watch(filterStatus, () => {
  fetchBookings()
})

onMounted(() => {
  fetchBookings()
})
</script>