// src/firebase.ts
import { initializeApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getStorage, connectStorageEmulator } from 'firebase/storage'
import {
  initializeFirestore,
  connectFirestoreEmulator,
  enableNetwork,
  disableNetwork,
  collection,
  doc,
  getDoc,
  persistentLocalCache,
  persistentMultipleTabManager,
  CACHE_SIZE_UNLIMITED
} from 'firebase/firestore'

// Get environment
const getEnvironment = () => {
  if (import.meta.env.PROD) return 'production';
  if (import.meta.env.DEV) return 'development';
  return 'development';
};

// Validate required environment variables
const requiredEnvVars = [
  'VITE_FIREBASE_API_KEY',
  'VITE_FIREBASE_AUTH_DOMAIN',
  'VITE_FIREBASE_PROJECT_ID',
  'VITE_FIREBASE_STORAGE_BUCKET',
  'VITE_FIREBASE_MESSAGING_SENDER_ID',
  'VITE_FIREBASE_APP_ID'
] as const;

requiredEnvVars.forEach(varName => {
  if (!import.meta.env[varName]) {
    throw new Error(`Missing required environment variable: ${varName}`);
  }
});

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID
};

const environment = getEnvironment();
console.log(`Initializing Firebase for ${environment} environment`);

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Initialize services
const auth = getAuth(app);
const storage = getStorage(app);

// Initialize Firestore with enhanced cache configuration
const db = initializeFirestore(app, {
  localCache: persistentLocalCache({
    tabManager: persistentMultipleTabManager(),
    cacheSizeBytes: CACHE_SIZE_UNLIMITED
  })
});

// Connect to emulators in development
if (environment === 'development') {
  try {
    connectAuthEmulator(auth, 'http://127.0.0.1:9099');
    connectFirestoreEmulator(db, '127.0.0.1', 9090);
    connectStorageEmulator(storage, '127.0.0.1', 9199);
    console.log('Connected to Firebase emulators');
  } catch (error) {
    console.error('Failed to connect to Firebase emulators:', error);
  }
}

// Enhanced error handler for Firebase operations
window.addEventListener('unhandledrejection', async (event) => {
  const error = event.reason;
  if (error?.name === 'FirebaseError') {
    console.error('Firebase error:', {
      code: error.code,
      message: error.message,
      environment: environment,
      details: error.serverResponse
    });
    
    // Handle specific error conditions
    switch (error.code) {
      case 'storage/unknown':
        console.error('Storage error details:', {
          serverResponse: error.serverResponse,
          customData: error.customData
        });
        break;
      case 'storage/unauthorized':
        console.error('Storage access denied. Please check authentication and permissions.');
        if (auth.currentUser) {
          await auth.currentUser.reload();
        }
        break;
      case 'storage/canceled':
        console.error('Storage operation was cancelled.');
        break;
      case 'storage/quota-exceeded':
        console.error('Storage quota exceeded.');
        break;
      case 'failed-precondition':
        console.error('Multiple tabs might be open. Please use only one tab.');
        break;
      case 'unavailable':
        console.error('Service is currently unavailable. Attempting to reconnect...');
        await handleConnectivityIssue();
        break;
      case 'permission-denied':
        console.error('Permission denied. Please check your authentication status.');
        if (auth.currentUser) {
          await auth.currentUser.reload();
        }
        break;
      case 'resource-exhausted':
      case 'deadline-exceeded':
        console.error('Connection timeout. Attempting to recover...');
        await handleConnectivityIssue();
        break;
    }
  }
});

// Enhanced connectivity handler
async function handleConnectivityIssue() {
  try {
    // Wait for a moment before trying to reconnect
    await new Promise(resolve => setTimeout(resolve, 2000));
    
    // Try to enable network
    await enableNetwork(db);
    console.log('Network connection restored');
  } catch (error) {
    console.error('Failed to recover connection:', error);
  }
}

// Enhanced Firestore connection verification
const waitForFirestore = async (): Promise<boolean> => {
  const maxRetries = environment === 'production' ? 5 : 3;
  const retryDelay = 1000; // 1 second

  for (let attempt = 0; attempt < maxRetries; attempt++) {
    try {
      // Try to verify connection using products collection (which has public read access)
      const productsCollection = collection(db, 'products');
      await getDoc(doc(productsCollection, 'test_connection'));
      console.log('Firestore connection verified');
      return true;
    } catch (error) {
      console.error(`Error connecting to Firestore (attempt ${attempt + 1}/${maxRetries}):`, error);
      
      // Check if it's an authentication error
      if (error?.code === 'permission-denied') {
        // Wait for auth state to be determined
        await new Promise<void>((resolve) => {
          const unsubscribe = auth.onAuthStateChanged((user) => {
            unsubscribe();
            resolve();
          });
        });
      }
      
      if (attempt === maxRetries - 1) {
        throw error;
      }

      // Wait before retrying with exponential backoff
      await new Promise(resolve => setTimeout(resolve, retryDelay * Math.pow(2, attempt)));
    }
  }

  throw new Error('Failed to establish Firestore connection after multiple attempts');
};

console.log(`Firebase initialized successfully for ${environment} environment`);

export {
  app,
  auth,
  db,
  storage,
  waitForFirestore,
  enableNetwork,
  disableNetwork,
  handleConnectivityIssue
};